import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from '../layout';
import PostListing from '../components/PostListing';
import { useSiteMetadata } from '../utils/useSiteMetadata';

// @ts-expect-error Property 'data' does not exist on type
const TagTemplate = ({ data, pageContext }) => {
  const siteMetadata = useSiteMetadata();

  return (
    <Layout>
      <main>
        <Helmet
          title={`Posts tagged as "${pageContext.tag}" | ${siteMetadata.title}`}
        />
        <PostListing postEdges={data.allMarkdownRemark.edges} />
      </main>
    </Layout>
  );
};
export default TagTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [fields___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            date(formatString: "MMMM DD, YYYY")
          }
          excerpt
          timeToRead
          frontmatter {
            title
            tags
            cover
            date
            categories
          }
        }
      }
    }
  }
`;
